body {
  font-family: "Inter", sans-serif;
  margin: 0;
  padding: 0;
  background-color: #f4f4f9;
}
.social-container {
   
  justify-content: center; 
  align-items: right;
}
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.animate-slideIn {
  animation: fadeIn 1.2s ease-in-out;
}
.about-text-container {
  max-width: 800px;
  margin-left: 20px;
  margin-right: 20px;
  margin-top: 30px;
  margin: auto ;
  font-size: 1.1rem;
  line-height: 1.8;
  background: rgba(255, 255, 255, 0.1);
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  color:white;
  
}
.buttonss {
  display: flex; 
  align-items: center; 
  gap: 1px; 
}

.timeline {
  
gap: 100px;
}

.timeline-item {
  border-left: 2px solid #ddd;
  padding: 10px 20px;
  position: relative;
  margin-bottom: 20px;
  color: white;

}

.timeline-item::before {
  content: '';
  position: absolute;
  left: -7px;
  top: 10px;
  background-color: #007bff;
  border-radius: 50%;
  width: 14px;
  height: 14px;
}

.timeline-date {
  font-size: 0.9rem;
  color: #9e9b9b;
  margin-bottom: 5px;
  display: block;
}




.shooting-stars {
  position: absolute;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  pointer-events: none;
  z-index: 1;
}

.shooting-star {
  position: absolute;
  width: 2px;
  height: 120px;
  background: linear-gradient(white, transparent);
  opacity: 0;
  animation: shooting-star-animation 5s ease-in-out infinite;
  transform-origin: bottom left;
}


.shooting-star:nth-child(1) { left: 10%; top: 50%; animation-delay: 5s; } 
.shooting-star:nth-child(3) { left: 70%; top: 50%; animation-delay: 2s; } 
.shooting-star:nth-child(4) { right: 0; top: 70%; animation-delay: 3s; transform-origin: right; } 


@keyframes shooting-star-animation {
  0% {
    opacity: 0;
    transform: translateY(0) translateX(0) rotate(45deg);
  }
  10% {
    opacity: 1;
  }
  60% {
    opacity: 1;
    transform: translateY(-500px) translateX(500px) rotate(45deg);
  }
  100% {
    opacity: 0;
    transform: translateY(-600px) translateX(600px) rotate(45deg);
  }
}


.shooting-star:nth-child(4) {
  animation: shooting-star-reverse 3s ease-in-out infinite;
  animation-delay: 1s;
}

@keyframes shooting-star-reverse {
  0% {
    opacity: 0;
    transform: translateY(0) translateX(0) rotate(-45deg);
  }
  10% {
    opacity: 1;
  }
  60% {
    opacity: 1;
    transform: translateY(-500px) translateX(-500px) rotate(-45deg);
  }
  100% {
    opacity: 0;
    transform: translateY(-600px) translateX(-600px) rotate(-45deg);
  }
}

/* Container */
.about-me-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  padding: 20px;
  color: white;
}

/* Content Wrapper */
.about-me-content {
  display: flex;
  max-width: 1200px;
  width: 100%;
  align-items: center;
  gap: 40px;
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 1s ease, transform 1s ease;
}

.about-me-content.animate-fadeIn {
  opacity: 1;
  transform: translateY(0);
}

/* Image Section */
.about-me-image-container {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.about-me-image {
  max-width: 300px;
  border-radius: 50%;
  border: 5px solid #fff;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.3);
  animation: float 4s ease-in-out infinite;
}

/* Floating Animation */
@keyframes float {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
}

/* Text Section */
.about-me-text-container {
  flex: 2;
  text-align: left;
  font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

.about-me-header {
  font-size: 2.5rem;
  font-family: 'Courier New', Courier, monospace;
  margin-bottom: 20px;
  color: #4c6abb;
  animation: slideInFromLeft 1s ease forwards;
}

.about-me-description {
  font-size: 1.2rem;
  line-height: 1.6;
  animation: fadeInText 2s ease;
}

/* Animations */
@keyframes slideInFromLeft {
  0% {
    transform: translateX(-50px);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes fadeInText {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@media (max-width: 768px) {
  .about-me-content {
    flex-direction: column;
    text-align: center;
    gap: 20px;
  }

  .about-me-image-container {
    flex: none;
    order: 1;
  }

  .about-me-image {
    max-width: 200px;
    margin: 0 auto;
  }

  .about-me-text-container {
    flex: none;
    order: 2;
  }

  .about-me-header {
    font-size: 2rem;
    margin-bottom: 15px;
  }

  .about-me-description {
    font-size: 1rem;
    line-height: 1.4;
  }
}
/* Container */
.experience-container {
  justify-content: center;
  align-items: center;
  padding: 30px;
  color: white;
}

/* Content Wrapper */
.experience-content {
  display: flex;
  max-width: 1200px;
  width: 100%;
  align-items: flex-start;
  gap: 40px;
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 1s ease, transform 1s ease;
}

.experience-content.animate-fadeIn {
  opacity: 1;
  transform: translateY(0);
}

/* Image Section */
.experience-image-container {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.experience-image {
  max-width: 300px;
  width: 100%;
  height: auto;
  border-radius: 50%;
  border: 5px solid #fff;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.3);
  animation: float 4s ease-in-out infinite;
}

/* Floating Animation */
@keyframes float {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
}

/* Text Section */
.experience-text-container {
  flex: 2;
}

.experience-header {
  font-size: 1.5rem;
  margin-bottom: 20px;
  color: #dcdee4;
  animation: slideInFromLeft 1s ease forwards;
  font-family: 'Courier New', Courier, monospace;
  text-align: center;
}

.timeline {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.timeline-item {
  font-size: 1.2rem;
  line-height: 1.6;
  animation: fadeInText 2s ease;
}

.timeline-date {
  font-weight: bold;
  color: #ffcc33;
  margin-bottom: 5px;
  display: block;
}

/* Animations */
@keyframes slideInFromLeft {
  0% {
    transform: translateX(-50px);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes fadeInText {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/* Responsive Design for Mobile Screens */
@media (max-width: 768px) {
  .experience-content {
    flex-direction: column;
    text-align: center;
    gap: 20px;
  }
  .experience-container{
    display: block
  }

  .experience-image-container {
    flex: none;
    order: 1;
    display: flex; /* Added */
    justify-content: center; /* Added */
    align-items: center; /* Added */
  }

  .experience-image {
    max-width: 200px;
    margin: 0 auto;
    display: block; /* Added */
  }

  .experience-text-container {
    flex: none;
    order: 2;
  }

  .experience-header {
    font-size: 2rem;
    margin-bottom: 15px;
  }

  .timeline-item {
    font-size: 1rem;
    line-height: 1.4;
  }

  .timeline-date {
    font-size: 0.9rem;
  }
}

