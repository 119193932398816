.dropdown {
    position: fixed;
    display: inline-block;
    z-index: 9999; /* Ensures it's on top of other content */

  }
  .p-2,
.p-3 {
  padding: 10px;
  font-family:'Courier New', Courier, monospace; /* System default cursive font */
  margin: 10px;
  border:#031530;
  border-radius: 25px;
  font-size: 20px;
  color: white;
  background-color: #121f38;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s, box-shadow 0.3s;
  letter-spacing: 1.5px;
  box-shadow: 0 4px 6px rgba(255, 255, 255, 0.1);
}
.p-4 {
  font-family:'Courier New', Courier, monospace;
  font-weight: 300;
  font-style: italic;
  font-size: 16px;
  margin: 10px;
  border: none;
  border-radius: 25px;
  color: white;
  background-color: #121f38;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s, box-shadow 0.3s;
  text-transform: uppercase;
  letter-spacing: 1.5px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  position: fixed;
  top: 10px;
  z-index: 9999; /* Ensures it's on top of other content */
}

@media (max-width: 767px) {
  .p-2 {
margin: 5px ;
padding:15px ;
font-family: 'Courier New', Courier, monospace;
width: 50vh;
display: flex;
align-items: center;
justify-content: center;

  }
}
.p-2:hover,
.p-3:hover {
  background-color: #061430;
  transform: scale(1.05);
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
}

.p-2:active,
.p-3:active {
  transform: scale(0.95);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.p-2:focus,
.p-3:focus {
  outline: none;
  box-shadow: 0 0 0 3px rgba(16, 17, 16, 0.5);
}
  .dropdown-toggle {
    border: 1px solid #031530;
    border-radius: 4px;
    padding: 8px 12px;
    cursor: pointer;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .dropdown-arrow {
    margin-right: 30px;
  }
  
  .dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    background: rgb(39, 64, 114);
    border: 1px solid #042142;
    border-radius: 4px;
    margin-top: 4px;
    z-index: 1000;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    max-height: 0; /* Initially hidden */
    overflow: hidden; /* Hide overflowing content */
    transition: max-height 0.4s ease; /* Smooth transition */
    padding:10px;
    margin-left: 10px;
    margin-right: 20px;
  }
  
  @keyframes slideDown {
    from {
      max-height: 0;
      opacity: 0;
      transform: scaleY(0.5); /* Start slightly compressed */
    }
    to {
      max-height: 200px; /* Adjust to the expected height */
      opacity: 1;
      transform: scaleY(1); /* Fully expanded */
    }
  }
  
  @keyframes slideUp {
    from {
      max-height: 200px; /* Adjust to the expected height */
      opacity: 1;
      transform: scaleY(1); /* Fully expanded */
    }
    to {
      max-height: 0;
      opacity: 0;
      transform: scaleY(0.5); 
    }
  }
  
  .dropdown-menu.open {
    animation: slideDown 0.4s ease forwards; 
  }
  
  .dropdown-menu.closed {
    animation: slideUp 0.4s ease forwards; 
  }
  
  .dropdown-item.highlighted {
    background-color: #061430; 
    color: #ffffff; 
    font-weight: bold; 
  }
  
  .dropdown-item {
    padding: 8px 12px;
    cursor: pointer;
    font-size: 14px;
    color: #ffffff;
  }
  
  .dropdown-item:hover {
    background-color: #1f2329;
  }
  