/* General Styles */
body {
  font-family: "Arial", sans-serif;
  margin: 0;
  padding: 0;
  background-color: #f5f5f5;
  color: #333;
}

.skills-page {
  padding: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  animation: fadeIn 1s ease-in-out;
}

/* Animation */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Profile Image */
.profile-image-container {
  margin-bottom: 20px;
  text-align: center;
}

.profile-image {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
  border: 5px solid #ffffff;

  animation: float 4s ease-in-out infinite;
}

/* Floating Animation */
@keyframes float {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
}

/* Header Styles */
.skills-header {
  font-size: 2.5em;
  margin-bottom: 30px;
  text-align: center;
  font-size: 2.5rem;
  font-family: 'Courier New', Courier, monospace;
  margin-bottom: 20px;
  color: #4c6abb;
}

/* Section Styles */
.skills-section {
  margin-bottom: 40px;
  width: 100%;
}

.skills-section h2 {
  font-size: 1.2em;
  margin-bottom: 15px;
  color: #d6d3d3;
  border-left: 5px solid #0073e6;
  padding-left: 10px;
}

/* Grid Layout */
.skills-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  gap: 20px;
  width: 100%;
}

/* Skill Cards */
.skill-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 15px;
  background-color: #0d12314b;
  border: 1px solid #413d3d;
  border-radius: 50px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.skill-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 8px 12px rgba(0, 0, 0, 0.2);
}

/* Skill Image */
.skill-image {
  width: 80px;
  height: 80px;
  object-fit: cover;
  margin-bottom: 10px;
  border-radius: 50%;
  border: 2px solid #0073e6;
  background-color: #f5f5f5;
}

/* Skill Name */
.skill-name {
  font-size: 1em;
  color: #f1e7e7;
  text-align: center;
  margin-top: 5px;
}

/* Skills List */
.skills-list {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.skills-list li {
  font-size: 0.8em;
  background-color: #0f5091;
  color: #fff;
  padding: 5px 15px;
  border-radius: 5px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

/* Responsive Design */
@media (max-width: 768px) {
  .profile-image {
    width: 120px;
    height: 120px;
  }

  .skills-header {
    font-size: 2em;
  }

  .skills-section h2 {
    font-size: 1.5em;
  }

  .skills-list li {
    font-size: 0.9em;
    padding: 5px 10px;
  }
}
