

.p-2:hover,
.p-3:hover {
  background-color: #061430;
  transform: scale(1.05);
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
}

.p-2:active,
.p-3:active {
  transform: scale(0.95);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.p-2:focus,
.p-3:focus {
  outline: none;
  box-shadow: 0 0 0 3px rgba(16, 17, 16, 0.5);
}

.js-text {
  text-align: left;
  margin: 20px auto;
  border: 2px solid #696464;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 800px;
}
.line-breaker {
  border: 0;
  height: 1px;
  background: #ffffff;
  margin: 10px 0;
}

.experience-content {
  display: flex;
  justify-content: space-between;
  gap: 20px; 
}

.dynamic-text {
  display: inline-block;
  padding: 10px 20px;
  border-radius: 30px;
  background-color: #0d203a;
  color: #e7e7e7;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  cursor: pointer;
}

.dynamic-text:hover {
  transform: scale(1.1); 
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2); 
  background-color: #0d1f44; 
}


.experience-block {
  flex: 1;
  padding: 10px;
  box-shadow: 0 4px 8px rgba(255, 255, 255, 0.692);
  background-color: #4a7bac62;
  border-radius: 20px;
  margin-top: 20px;
  margin: 30px;
}

.js-texts {
  display: block;
  margin-bottom: 20px;
  color:rgb(189, 197, 221)
}

.menu{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Optional: Add a subtle shadow */
}
.menu.show-at-top {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;
  background-color: white;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.profile-pic-container {
  display: flex;
  justify-content: center;
}


.profile-pic {
  border-radius: 50%;
margin-bottom: 25px;
}

.about-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-size: cover;
}
@media (max-width: 480px) {
  .header {
    font-size: 24px;
    margin: 20px 0;
    text-align: center;
  }

  .js-text {
    margin: 10px;
    padding: 15px;
    font-size: 14px;
    
  }

 

  .profile-pic-container {
    justify-content: center;
    margin-right: 0;
  }

  .about-container {
    flex-direction: column;
    align-items: center;
  }

  .experience-content {
    flex-direction: column;
  }
  
}
@media (max-width: 767px) {
  .header {
    font-size: 24px;
    margin: 20px 0;
    text-align: center;
  }

  .js-text {
    margin: 10px;
    padding: 15px;
    font-size: 14px;
    
  }


  .profile-pic-container {
    justify-content: center;
    margin-right: 0;
  }

  .about-container {
    flex-direction: column;
    align-items: center;
  }

  .experience-content {
    flex-direction: column;
  }
  
}
.menu-slide {
  transition: transform 2s ease-in-out;
  

}

.slide-right {
  transform: translateX(400%); 
}

.slide-reset {
  transform: translateX(0);
}
