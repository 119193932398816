.projects-container {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    padding: 20px;
    justify-content: center;
    
  }
  html, body {
    margin: 0;
    padding: 0;
    height: 200vh; 
}
  
  .project-block {
    background-color: #335e99c5;
    border: 1px solid #000000;
    border-radius: 70px;
    box-shadow: 0 4px 100px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    width: 300px;
    transition: transform 0.3s;
    display: flex; 
    flex-direction: column; 
    margin-top: 50px;

  }
  
  .project-block:hover {
    transform: translateY(-20px);
  }
  
  .project-image {
    width: 100%;
    height: 200px;
    object-fit: cover;
  }
  
  .drillshare-img {
    width: 10;
    height: 200px;
    object-fit: cover;
  }
  
  .project-content {
    padding: 15px;
    text-align: left;
    flex-grow: 1; 
    display: flex; 
    flex-direction: column; 
  }
  
  .project-title {
    font-size: 1.2em;
    color: #cccbcb;
    background: none;
    border: none;
    padding: 0;
    cursor: pointer;
    text-align: left;
    font-weight: bold;
    text-decoration: underline;
    margin-top: auto;
  }
  
  
  
  .project-description {
    font-size: 0.9em;
    color: #000000;
    margin-top: 10px;
  }
  
  
  .drillshare-image {
    width: 100%;
    height: 75px;
    object-fit: cover; 
    transform: scale(0.8); 
    transform-origin: center; 
    margin-top: 50px;
  }
  .drillshare-special{
    margin-top: 50px;
  }
  .App {
    text-align: center;
  }
  
  
  
  @keyframes gradientAnimation {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
  
  .backgroun-image{
    margin-bottom: 200px;

  }
  .background-image {
background-attachment: fixed;  
 
    background-image: url("../images/pexels-frank-cone-140140-3214110.jpg");
    background-repeat: no-repeat;
    background-size: cover; 
    background-position: center;
}

@media (max-width: 768px) {
  .background-image {
    
      overflow: hidden; 
      background-color: #021c3f;
      background: #000813;
  }
}
  
  @media (max-width: 767px) {
    .App-header {
      padding: 10px;
    }
  
    .rotating-earth {
      width: 80px;
      height: 80px;
    }
  
    .title-text {
      font-size: 1.5rem;
      font-family: 'Courier New', Courier, monospace;
    }
  
    .title {
      padding: 0 20px;
      text-align: center;
    }
  
  }
 
  .socia {
   
    justify-content: center; 
    align-items: right;
    margin-top: auto; 
  }
  
  .social-button {
    width: 50px;
    height: 50px;
    background-color: #335d999c; 
    display: flex;
    align-items: center;
    justify-content: center; 
    border-radius: 50%;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: background-color 0.3s ease;
    margin-left:10px;
    margin-bottom: 10px;
  }
  
  .social-button img {
    width: 24px; 
    height: 24px; 
  }
  
  .social-button:hover {
    background-color: #021c3f;
  }
  
  .profile-pic {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    object-fit: cover;
    border: 2px solid #333;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
  
  .linkedin-button {
    display: inline-block;
    width: 50px;
    height: 50px;
    background-color: #335d9996; 
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: background-color 0.3s ease;
    margin-top: 20px;
    margin-bottom: 15px;
    margin-right: 20px;
  }
  
  .linkedin-button img {
    width: 24px; 
    height: 24px; 
  }
  
  .linkedin-button:hover {
    background-color: #021c3f;
  }
  
  @keyframes slideIn {
    from {
      opacity: 0;
      transform: translateX(-100%);
    }
    to {
      opacity: 1;
      transform: translateX(0);
    }
  }
  
  .animate-slideIn {
    animation: slideIn 2s ease-in-out forwards;
  }


.animate-slide-in {
  opacity: 0;
  transform: translateY(50px);
  transition: all 1s ease-in-out;
}

.animate-slide-in.visible {
  opacity: 1;
  transform: translateY(0);
}
  
 